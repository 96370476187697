<template>
  <div class="container" id="Traffic">
    <div class="header">
      <BaseHeader
        title="Gestão de tráfego"
        style="padding: 0 !important"
        :navigation="navigation"
      ></BaseHeader>
    </div>

    <div
      class="section-values"
      v-if="!loading && all_traffic && all_traffic.length > 0"
    >
      <!--Acessos-->
      <div class="container-value">
        <div class="icon">
          <img
            src="../assets/icons/trafego/iconAccess.svg"
            alt="Acessos"
            v-b-tooltip="{
              title: formatNumberTooltip(access),
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>Acessos</h2>
          <span v-if="access != 0">{{ access | formatNumber }}</span>
          <span style="cursor: pointer" v-else @click="openFilters"
            >Selecione seu plano</span
          >
        </div>
      </div>

      <!--Leads-->
      <div class="container-value">
        <div class="icon">
          <img
            src="@/assets/img/icons/affiliate.svg"
            alt="Acessos"
            v-b-tooltip="{
              title: info_traffic.leads,
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>Leads</h2>
          <span>{{ info_traffic.leads | formatNumber }}</span>
        </div>
      </div>

      <!--Investido-->
      <div class="container-value">
        <div class="icon">
          <img
            src="../assets/icons/trafego/iconSales.svg"
            alt="Investido"
            v-b-tooltip="{
              title: formatMoneyTooltip(info_traffic.investing),
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>Investido</h2>
          <span>{{ info_traffic.investing | formatMoney }} </span>
        </div>
      </div>

      <!--CPL-->
      <div class="container-value">
        <div class="icon">
          <img
            src="../assets/icons/trafego/iconLucro.svg"
            alt="CPL"
            v-b-tooltip="{
              title: formatMoneyTooltip(cpl),
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>CPL</h2>
          <span>{{ cpl | formatMoney }}</span>
        </div>
      </div>

      <!--Captação-->
      <div class="container-value">
        <div class="icon">
          <img
            src="../assets/icons/trafego/iconMoney.svg"
            alt="Captação"
            v-b-tooltip="{
              title: formatMoneyTooltip(info_traffic.capture),
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>Captação</h2>
          <span>{{ info_traffic.capture | formatMoney }}</span>
        </div>
      </div>

      <!--Remarketing-->
      <div class="container-value">
        <div class="icon">
          <img
            src="../assets/icons/trafego/iconTrending.svg"
            alt="Remarketing"
            v-b-tooltip="{
              title: formatMoneyTooltip(info_traffic.remarketing),
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>Remarketing</h2>
          <span>{{ info_traffic.remarketing | formatMoney }}</span>
        </div>
      </div>

      <!--Outros-->
      <div class="container-value">
        <div class="icon">
          <img
            src="../assets/icons/trafego/iconRoi.svg"
            alt="Outros"
            v-b-tooltip="{
              title: formatMoneyTooltip(info_traffic.others),
              placement: 'top',
            }"
          />
        </div>
        <div>
          <h2>Outros</h2>
          <span>{{ info_traffic.others | formatMoney }}</span>
        </div>
      </div>
    </div>

    <div class="section-values" v-if="loading">
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
      <b-skeleton height="82px" width="100%" class="mr-4"></b-skeleton>
    </div>

    <div class="header-table mt-5">
      <div style="display: flex; gap: 8px">
        <div class="datas" v-if="!isMobile">
          <DateRangePicker
            v-model="dateRange"
            ref="picker"
            opens="right"
            class="dateFilter"
            :showWeekNumbers="false"
            :locale-data="localeDate"
            :showDropdowns="true"
            :autoApply="false"
            :ranges="dateRanges"
            @update="getTraffic(search)"
          >
            <!--  <template v-slot:input="picker"
                >{{ dateRange.startDate | date }} à
                {{ dateRange.endDate | date }}
              </template>  -->
            <template
              v-if="dateRange.startDate && dateRange.endDate"
              v-slot:input="picker"
            >
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
            <template v-else v-slot:input> Selecione as datas </template>
          </DateRangePicker>
        </div>
        <div v-if="!isMobile">
          <b-tooltip target="filtro-1" title="Aplicar filtro" placement="top" />
          <b-button
            id="filtro-1"
            class="btn-table"
            @click="openFilters"
            title="Filtros"
            ><img src="@/assets/img/icons/filtro.svg"
          /></b-button>
        </div>
      </div>
      <div style="display: flex; gap: 8px">
        <div class="inputSearch" style="position: relative">
          <img src="@/assets/img/icons/search.svg" class="Pointer search" />
          <input
            type="text"
            placeholder="Pesquise"
            v-model="search"
            @input="debounce"
            class="input-busca mb-0"
          />
        </div>
        <BaseButton variant="primary" @click="openModal('Modal-traffic')">
          {{ isMobile ? "Criar" : "Criar Lançamento" }}
        </BaseButton>
      </div>
    </div>

    <div class="mt-5" v-if="!isMobile">
      <b-row class="Table-header mb-1">
        <!--ID-->
        <b-col cols="1" class="d-none d-md-block" style="padding-left: 20px">
          ID
        </b-col>

        <!--Título-->
        <b-col cols="2" class="d-none d-md-block" style="padding-left: 20px">
          Plano
        </b-col>

        <!--Canal-->
        <b-col cols="2" class="d-none d-md-block"> Canal </b-col>

        <!--Valor-->
        <b-col cols="3" class="d-none d-md-block"> Valor </b-col>

        <!--Dt. Criação-->
        <b-col cols="3" class="d-none d-md-block" style="padding-left: 15px">
          Dt. Investimento
        </b-col>

        <!--Ações-->
        <b-col cols="1" class="d-none d-md-block" style="padding-left: 15px"
          >Ações</b-col
        >
      </b-row>
      <template v-if="!loading">
        <div
          v-for="(traffic, index) in all_traffic"
          :key="index"
          class="Table-body-container"
        >
          <b-row class="Table-body" id="border-bottom">
            <!--ID-->
            <b-col cols="1" style="word-break: break-all; color: #141414">
              {{ traffic.id }}
            </b-col>

            <!--NOME DO PLANO-->
            <b-col cols="2" style="word-break: break-all; color: #141414">
              {{ traffic.title }}
            </b-col>

            <!--CANAIS-->
            <b-col cols="2" class="d-flex">
              <img
                v-if="traffic.origin == 'MetaAds'"
                src="../assets//icons/trafego/iconMeta.svg"
                style="margin-left: 5px"
                alt="MetaAds"
              />
              <img
                v-if="traffic.origin == 'Twitter'"
                src="../assets//icons/trafego/iconTwitter.svg"
                style="margin-left: 5px"
                alt="Twitter"
              />
              <img
                v-if="traffic.origin == 'TikTok'"
                src="../assets//icons/trafego/iconTiktok.svg"
                style="margin-left: 5px"
                alt="TikTok"
              />
              <img
                v-if="traffic.origin == 'Linkedin'"
                src="../assets//icons/trafego/iconLinkedin.svg"
                style="margin-left: 5px"
                alt="Instagram"
              />

              <img
                v-if="traffic.origin == 'GoogleAds'"
                src="../assets//icons/trafego/iconGoogleAds.svg"
                style="margin-left: 5px"
                alt="Instagram"
              />
            </b-col>

            <!--VALOR-->
            <b-col cols="3">
              <span style="color: #141414">{{
                traffic.investing | formatMoney
              }}</span>
            </b-col>

            <!--DATA DE INVESTIMENTO-->
            <b-col cols="3" class="d-flex" style="color: #141414" :key="index">
              {{ dateInvestStart(traffic) | moment("DD/MM/YYYY") }} -
              {{ dateInvestEnd(traffic) | moment("DD/MM/YYYY") }}
            </b-col>

            <!--AÇOES-->
            <b-col cols="1" class="d-flex">
              <b-dropdown id="dropForm" right>
                <template #button-content>
                  <img
                    src="@/assets/img/icons/dots-black.svg"
                    alt="dots"
                    class="ml-2"
                  />
                </template>
                <b-dropdown-item @click="editTraffic(traffic)"
                  >Editar</b-dropdown-item
                >
                <b-dropdown-item @click="deleteHard(traffic)"
                  >Deletar</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </div>
      </template>
      <div v-else>
        <div v-for="(item, index) in 10" :key="index">
          <div
            class="d-flex align-items-center justify-content-around my-3 mt-5"
          >
            <b-col cols="1">
              <b-skeleton width="30px" height="30px"></b-skeleton>
            </b-col>

            <b-col cols="2">
              <b-skeleton height="10px" width="120px" class="ml-3"></b-skeleton>
            </b-col>

            <b-col cols="2">
              <b-skeleton width="30px" height="30px " class="ml-2"></b-skeleton>
            </b-col>

            <b-col cols="3">
              <b-skeleton height="10px" width="120px" class=""></b-skeleton>
            </b-col>

            <b-col cols="3">
              <b-skeleton height="10px" width="120px" class=""></b-skeleton>
            </b-col>

            <b-col cols="1">
              <b-skeleton height="10px" width="35px" class=""></b-skeleton>
            </b-col>
          </div>
        </div>
      </div>
      <b-row>
        <b-col>
          <Paginate
            v-if="all_traffic.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :disabled="true"
          />
        </b-col>
      </b-row>
    </div>

    <!--HEADER MOBILE-->
    <div v-if="isMobile" class="mt-4">
      <b-row
        class="Table-header mb-1"
        style="
          padding: 15px 5px !important;
          gap: 0 !important;
          justify-content: space-between;
        "
        v-if="isMobile"
      >
        <b-col style="text-align: start" cols="3"> Plano </b-col>
        <b-col cols="1"> Canal </b-col>
        <b-col cols="4" style="text-align: end"> Valor </b-col>
      </b-row>

      <template v-if="!loading">
        <div
          v-for="(traffic, index) in all_traffic"
          :key="index"
          class="Table-body-container"
        >
          <b-row
            class="Table-body"
            style="
              padding: 0 5px !important;
              gap: 0 !important;
              justify-content: space-between;
            "
            id="border-bottom"
          >
            <!--NOME DO PLANO-->
            <b-col cols="3" style="word-break: break-all; color: #141414">
              {{ traffic.title }}
            </b-col>

            <!--CANAIS-->
            <b-col cols="1" class="d-flex">
              <img
                v-if="traffic.origin == 'MetaAds'"
                src="../assets//icons/trafego/iconMeta.svg"
                style="margin-left: 5px"
                alt="Instagram"
                :class="{ 'icon-channel': isMobile }"
              />
              <img
                v-if="traffic.origin == 'Twitter'"
                src="../assets//icons/trafego/iconTwitter.svg"
                style="margin-left: 5px"
                alt="Twitter"
                :class="{ 'icon-channel': isMobile }"
              />
              <img
                v-if="traffic.origin == 'TikTok'"
                src="../assets//icons/trafego/iconTiktok.svg"
                style="margin-left: 5px"
                alt="TikTok"
                :class="{ 'icon-channel': isMobile }"
              />
              <img
                v-if="traffic.origin == 'Linkedin'"
                src="../assets//icons/trafego/iconLinkedin.svg"
                style="margin-left: 5px"
                alt="Instagram"
                :class="{ 'icon-channel': isMobile }"
              />

              <img
                v-if="traffic.origin == 'GoogleAds'"
                src="../assets//icons/trafego/iconGoogleAds.svg"
                style="margin-left: 5px"
                alt="Instagram"
                :class="{ 'icon-channel': isMobile }"
              />
            </b-col>

            <!--VALOR-->
            <b-col cols="4" style="text-align: end">
              <span style="color: #141414">{{
                traffic.investing | formatMoney
              }}</span>
            </b-col>
          </b-row>
        </div>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="all_traffic.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :disabled="true"
          />
        </b-col>
      </b-row>
    </div>
    <b-row
      style="
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -70%);
        width: 100%;
      "
      v-if="
        !loading && all_traffic && all_traffic.length === 0 && search.length > 0
      "
      class="justify-content-center"
    >
      <p class="nao-encontrado">Nenhum tráfego foi encontrado</p>
    </b-row>

    <b-row
      style="
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -70%);
        width: 100%;
      "
      v-if="!loading && all_traffic.length === 0 && search.length === 0"
      class="justify-content-center"
    >
      <p class="nao-encontrado">Nenhum tráfego criado</p>
    </b-row>
    <ModalCreateTraffic @create-traffic="getTraffic" />
    <EditTraffic
      :Traffic_selected="Traffic_selected"
      @create-traffic="getTraffic"
    />
    <ModalFiltersPipeline />
    <ModalFilterTraffic
      @create-traffic="getTraffic"
      @selected-channel-title="selectedChannelTitle"
      @selected-plan-id="selectedPlanId"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Money } from "v-money";
import _ from "lodash";
//
import Paginate from "@/components/Paginate";
import BaseView from "@/template/BaseView.vue";
import ModalCreateTraffic from "@/components/Simulador/ModalCreateTraffic";
import ModalFiltersPipeline from "@/components/Pipeline/ModalFiltersPipeline";
import ModalFilterTraffic from "@/components/Simulador/ModalFilterTraffic.vue";
import EditTraffic from "@/components/Simulador/ModalEditTraffic.vue";

//
import DateRangePicker from "vue2-daterange-picker";
import TrafficService from "@/services/resources/TrafficService";
const serviceTraffic = TrafficService.build();
//
import moment from "moment-timezone";
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
//
import DashboardTraffic from "../services/resources/DashboardTraffic";
const serviceDashboardTraffic = DashboardTraffic.build();
//
import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();
//
export default {
  components: {
    Paginate,
    BaseView,
    Money,
    EditTraffic,
    ModalCreateTraffic,
    DateRangePicker,
    ModalFiltersPipeline,
    ModalFilterTraffic,
  },
  data() {
    return {
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Gestão de tráfego", to: this.$route.fullPath },
      ],

      dash: null,
      channelTitle: "",
      planId: "",
      amount: 0,
      Traffic_selected: 0,
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: "01/01/15",
        endDate: moment(),
      },

      all_traffic: [],
      info_traffic: [],
      search: "",
      total: null,
      status: true,
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      client: {
        width: 0,
      },

      cpl: 0,
      ids_campaigns: [],
      access: 0,
    };
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);

        if (number > 1000000) {
          const millions = (number / 1000000).toFixed(1);
          return `R$ +${millions} mi`;
        }

        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return `R$ ${number.toLocaleString("pt-BR", formato)}`;
      }

      return "R$ 0,00";
    },

    formatNumber(valor) {
      if (valor >= 1000000) {
        return (valor / 1000000).toFixed(1) + "mi";
      } else {
        return valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let nowtoday = new Date();
      nowtoday.getDay() + 1;

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, nowtoday],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    selectedChannelTitle(title) {
      this.channelTitle = title;
    },

    selectedPlanId(id) {
      this.planId = id;

      this.ids_campaigns = [];
      const idString = id.campaign_id.toString();
      if (!this.ids_campaigns.includes(idString)) {
        this.ids_campaigns.push(idString);
      }

      this.getMetrics();
    },
    openFilters() {
      this.$bvModal.show("Modal-filter-traffic");
    },
    formatMoneyTooltip(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return `R$ ${number.toLocaleString("pt-BR", formato)}`;
      }

      return "R$ 0,00";
    },

    formatNumberTooltip(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    deleteHard(id) {
      Vue.swal({
        title: "Tráfefo",
        text: `Deseja realmente deletar o tráfego?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: id.id,
          };

          const campaign = id.metas.find((x) => x.meta_key === "campaign_id");

          if (campaign) {
            const campaignId = campaign.meta_value;

            if (!this.ids_campaigns.includes(campaignId)) {
              this.ids_campaigns.push(campaignId);
            }

            const indexToRemove = this.ids_campaigns.indexOf(campaignId);
            if (indexToRemove !== -1) {
              this.ids_campaigns.splice(indexToRemove, 1);
            }
          }

          serviceTraffic
            .destroy(data)
            .then(() => {
              this.loading = false;
              this.$bvToast.toast("Tráfego deletado com sucesso", {
                title: "Tráfego",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getTraffic();
              this.getMetrics();
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar tráfego", {
                title: "Tráfego",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // active(id) {
    //   Vue.swal({
    //     title: "Planos",
    //     text: `Deseja restaurar a simulação?`,
    //     type: "danger",
    //     showCancelButton: true,
    //     confirmButtonText: "Restaurar",
    //     cancelButtonText: "Cancelar",
    //     customClass: "sweet-container",
    //     confirmButtonClass: "button button-primary mt-3 mb-3",
    //     cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
    //     buttonsStyling: false,
    //     reverseButtons: true,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.loading = true;
    //       var data = {
    //         id: id,
    //         status: "active",
    //       };
    //       serviceTraffic
    //         .update(data)
    //         .then(() => {
    //           this.$bvToast.toast("Simulação restaurada com sucesso", {
    //             title: "Planos",
    //             variant: "info",
    //             autoHideDelay: 5000,
    //             appendToast: true,
    //           });
    //           this.getPages();
    //         })
    //         .catch((error) => {
    //           this.$bvToast.toast("Erro ao restaurar simulação", {
    //             title: "Planos",
    //             variant: "danger",
    //             autoHideDelay: 5000,
    //             appendToast: true,
    //           });
    //         })
    //         .finally(() => {
    //           this.getTraffic(this.search);
    //           this.loading = false;
    //         });
    //     }
    //   });
    // },
    inactive(id) {
      Vue.swal({
        title: "Planos",
        text: `Deseja desativar a simulação?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desativar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: id,
            status: "inactive",
          };
          serviceTraffic
            .update(data)
            .then(() => {
              this.$bvToast.toast("Simulação inativado com sucesso", {
                title: "Planos",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar simulação", {
                title: "Planos",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.getTraffic(this.search);
              this.loading = false;
            });
        }
      });
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.getTraffic(this.search);

      const lowerCaseSearch = this.search.toLowerCase();

      var filteredTraffic = this.all_traffic.filter((x) =>
        x.title.toLowerCase().includes(lowerCaseSearch)
      );

      if (filteredTraffic.length > 0) {
        this.ids_campaigns = [];
      }
    }, 500),

    toPage(page) {
      this.pagination.currentPage = page;
      this.getTraffic(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.getTraffic(this.search);
    },
    changeStatusForm() {
      this.pagination.currentPage = 1;
      this.getTraffic(this.search);
    },

    getTraffic(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;
      const startDate = moment(this.dateRange.startDate);
      const endDate = moment(this.dateRange.endDate).add(1, "days");
      const formatStart = startDate.format(`YYYY-MM-DD 00:00:00`);
      const formatEnd = endDate.format(`YYYY-MM-DD 00:00:00`);

      var data = {
        id: `/list`,
        status: this.status ? "active" : "inactive",
        search: this.search,
        startDate: formatStart,
        endDate: formatEnd,
        plan_id: !this.planId
          ? []
          : this.planId.id == "Selecione o plano"
          ? []
          : this.planId.id,
        origin: !this.channelTitle
          ? []
          : this.channelTitle.title == "Selecione o canal"
          ? []
          : this.channelTitle.title,
      };

      serviceTraffic
        .createId(data)
        .then((resp) => {
          // Simulações
          this.all_traffic = resp.list.data;
          this.info_traffic = resp.info;

          this.cpl = this.info_traffic.capture / this.info_traffic.leads;

          let amount = 0;
          for (let i = 0; i < this.all_traffic.length; i++) {
            const element = this.all_traffic[i];

            var campaign = element.metas.find(
              (x) => x.meta_key === "campaign_id"
            );
            if (campaign) {
              this.ids_campaigns.push(campaign.meta_value);

              const investingValue = parseFloat(
                element.investing.replace(/[^\d,-]/g, "").replace(",", ".")
              );

              if (!isNaN(investingValue)) {
                amount += investingValue;
                this.amount = amount;
              }
            }
            this.getDash();
          }

          // this.total = resp.total;
          // this.pagination.totalPages = resp.last_page;
        })
        .finally(() => {
          this.getMetrics();
          this.loading = false;
        });
    },
    async getMetrics(search = null) {
      this.loading = true;
      const startDate = moment(this.dateRange.startDate);
      const endDate = moment(this.dateRange.endDate).add(1, "days");
      const formatStart = startDate.format(`YYYY-MM-DD HH:mm:ss`);
      const formatEnd = endDate.format(`YYYY-MM-DD HH:mm:ss`);

      let totalAccess = 0;
      let processedIds = new Set();

      for (let i = 0; i < this.ids_campaigns.length; i++) {
        var campaign_id = this.ids_campaigns[i];

        if (processedIds.has(campaign_id)) {
          continue;
        }

        var data = {
          start_date: formatStart,
          end_date: formatEnd,
          id: `metrics/${campaign_id}`,
        };

        // try {
        const resp = await serviceCampaign.createId(data);
        totalAccess += resp.views;
        this.access = totalAccess;
        processedIds.add(campaign_id);
        // } catch (err) {
        console.error(err);
        // } finally {
        this.loading = false;
        // }
      }
    },

    dateInvestStart(data) {
      const startDate = data.metas.find(
        (meta) => meta.meta_key === "startDate"
      );
      const endDate = data.metas.find((meta) => meta.meta_key === "endDate");

      if (startDate) {
        return startDate.meta_value;
      }
    },
    dateInvestEnd(data) {
      const endDate = data.metas.find((meta) => meta.meta_key === "endDate");

      if (endDate) {
        return endDate.meta_value;
      }
    },
    editTraffic(item) {
      this.Traffic_selected = item;
      // console.log('this.Traffic_selected', this.Traffic_selected)
      this.$bvModal.show("Modal-EditTraffic");
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.getTraffic();
    // this.getMetrics();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.icon-channel {
  width: 20px;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
  margin-top: 3px;
}

.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}

.btn-table img {
  filter: invert(50%);
}

.btn-table:first-child {
  background-color: var(--greenn2);
}

.btn-table:first-child:active {
  background-color: var(--greenn2);
  border: none;
}

.btn-table:first-child img {
  filter: invert(0);
}

.btn-table:nth-child(3) {
  margin-left: 15px;
}
.container {
  header {
    margin: -10px 0px 0px 0px !important;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px 0px;
  }
  .header-table {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    gap: 10px;
  }
  .icon {
    background-color: var(--greenn2);
    border-radius: 99999px;
    padding: 2px;
    height: 40px;
    width: 40px;
    img {
      width: 20px;
      height: 20px;
      margin: auto;
    }
  }
  .section-values {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    width: 100%;
    gap: 25px;

    .container-value {
      width: 100%;
      height: 82px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      padding: 10px 15px;
      display: flex;
      gap: 20px;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        h2 {
          color: #333;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.2px;
        }
        span {
          color: var(--greenn);
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.2px;
          // width: 90px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 6px;
      width: 100%;
      .container-value {
        width: 100%;
        gap: 16px;
        .icon {
          width: 37px;
          height: 37px;
          img {
            width: 17px;
          }
        }

        div {
          h2 {
            font-size: 15px;
          }
          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.datas .vue-daterange-picker {
  font-size: 14px !important;
  color: #81858e !important;
  width: 180px !important;
  text-align: center !important;
}
.dateFilter i {
  display: none !important;
}
.status {
  display: flex;
  align-items: center;
  gap: 15px;
}
.Table-body-container .Table-body {
  background-color: #ffffff !important;
}
.Table-body-container:nth-child(2n-2) .Table-body {
  background-color: #fafafb !important;
}
.input-busca {
  width: 230px;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #141414;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 20px;
  top: 17px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }
  .Table-body {
    gap: 50px;
  }

  .container-pesquisa {
    text-align: left;
    padding-bottom: 10px;
  }
  .search {
    left: 20px;
  }
}

.content-enviados {
  min-width: 78px;
  height: 32.73px;
  background: var(--greenn2);
  border-radius: 10px;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.value-enviados {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--greenn);
  margin: 0;
}

.pointer-enviados {
  background: var(--greenn);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.content-abertos {
  min-width: 78px;
  height: 32.73px;
  background: rgba(255, 203, 55, 0.1);
  border-radius: 10px;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.pointer-abertos {
  background: var(--orange);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.value-abertos {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--orange);
  margin: 0px;
}

.content-clicados {
  min-width: 78px;
  height: 32.73px;
  background: rgba(46, 215, 238, 0.1);
  border-radius: 10px;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.pointer-clicados {
  background: #2ed7ee;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.value-clicados {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2ed7ee;
  margin: 0px;
}
</style>
