<template>
  <BaseModal
    name="Modal-EditTraffic"
    id="Modal-EditTraffic"
    idModal="Modal-EditTraffic"
    size="lg"
    title="Gestão de dados"
    @shown="getTraffic()"
  >
    <div>
      <p class="desc">Atualize as informações da sua campanha.</p>
    </div>
    <div
      class="container-inputs mt-5"
      style="height: 300px !important"
      v-if="!loading"
    >
      <b-form-group label="Data dos dados" label-for="method">
        <DateRangePicker
          v-model="dateRange"
          ref="picker"
          opens="rigth"
          class="dateFilter"
          :showWeekNumbers="false"
          :locale-data="localeDate"
          :showDropdowns="true"
          :autoApply="false"
          :ranges="dateRanges"
        >
          <!-- <template v-slot:input="picker"
                >{{ dateRange.startDate | date }} à
                {{ dateRange.endDate | date }}
              </template> -->
          <template
            v-if="dateRange.startDate && dateRange.endDate"
            v-slot:input="picker"
          >
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
          <template v-else v-slot:input> Selecione as datas </template>
        </DateRangePicker>
      </b-form-group>
      <!-- Plano e valor investido -->
      <div style="display: grid; gap: 15px; grid-template-columns: 1fr 1fr">
        <b-form-group label="Plano" label-for="method" class="form-relative">
          <div>
            <multiselect
              style="margin-bottom: 3px !important"
              id="plan"
              v-model="selected_plan"
              label="title"
              track-by="id"
              :disabled="disabledPlan"
              placeholder="Selecione o plano"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="all_plans"
              :multiple="false"
              :taggable="false"
              :internal-search="true"
              :searchable="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
              @input="changePlannel"
            >
              <span slot="noOptions" style="font-size: 14px !important"
                >Pesquise por um plano</span
              >
              <span slot="noResult" style="font-size: 14px !important"
                >Oops! Nenhum plano encontrado.</span
              >
            </multiselect>

            <span
              class="msgError"
              v-show="errorPlan"
              v-if="selected_plan == '' || !selected_plan"
              >Selecione um plano</span
            >
          </div>
        </b-form-group>

        <b-form-group
          label="Investimento"
          label-for="method"
          class="form-relative"
        >
          <div>
            <money
              id="amount_invest"
              v-model="invest2"
              v-bind="money"
              placeholder="Valor investido"
              class="w-100"
            ></money>
          </div>
        </b-form-group>
      </div>

      <!-- Canal e categoria-->
      <div style="display: grid; gap: 15px; grid-template-columns: 1fr 1fr">
        <b-form-group label="Canal" label-for="method" class="form-relative">
          <div>
            <multiselect
              style="margin-bottom: 3px !important"
              id="channel"
              v-model="selected_channel"
              label="title"
              track-by="title"
              placeholder="Selecione o canal"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :searchable="false"
              :options="channels"
              :multiple="false"
              :taggable="false"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
            >
              <span slot="noOptions" style="font-size: 14px !important"
                >Pesquise por um canal</span
              >
              <span slot="noResult" style="font-size: 14px !important"
                >Oops! Nenhum canal encontrado.</span
              >
            </multiselect>
            <span
              class="msgError"
              v-show="errorChannel"
              v-if="selected_channel == '' || !selected_channel"
              >Selecione o canal</span
            >
          </div>
        </b-form-group>

        <b-form-group
          label="Objetivo de Campanha"
          label-for="method"
          class="form-relative"
        >
          <div>
            <multiselect
              style="margin-bottom: 3px !important"
              id="channel"
              v-model="selected_category"
              label="title"
              track-by="title"
              placeholder="Selecione o objetivo"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :searchable="false"
              open-direction="bottom"
              :options="categories"
              :multiple="false"
              :taggable="false"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
            >
              <span slot="noOptions" style="font-size: 14px !important"
                >Pesquise por um canal</span
              >
              <span slot="noResult" style="font-size: 14px !important"
                >Oops! Nenhum canal encontrado.</span
              >
            </multiselect>
            <span
              class="msgError"
              v-show="errorCategory"
              v-if="selected_category == '' || !selected_category"
              >Selecione a categoria</span
            >
          </div>
        </b-form-group>
      </div>
      <!-- Cliques e Impressoes -->
      <div
        style="
          display: grid;
          gap: 15px;
          grid-template-columns: 1fr 1fr;
          margin-top: 12px;
        "
      >
        <b-form-group
          label="Cliques (opcional)"
          label-for="method"
          class="form-relative"
        >
          <div>
            <input
              id="clicks"
              type="number"
              v-model="clicks"
              placeholder="Informe a quantidade de cliques"
            />
          </div>
        </b-form-group>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4 mt-5" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton
        variant="primary"
        class="mt-5"
        :disabled="loading"
        @click="onUpdate"
      >
        Atualizar item
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center loading" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import { Money } from "v-money";
//
import moment from "moment-timezone";
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
//
import FunnelnService from "@/services/resources/CampaignService";
const serviceFunnel = FunnelnService.build();
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
//
import ProductIntegrationService from "@/services/resources/ProductIntegrationService";
const serviceProductIntegration = ProductIntegrationService.build();
//
import TrafficService from "@/services/resources/TrafficService";
const serviceTraffic = TrafficService.build();
export default {
  name: "EditTraffic",
  components: {
    Money,
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().add(1, "days").format("YYYY-MM-DD"),
      },
      errorPlan: false,
      errorChannel: false,
      errorCategory: false,
      infoInvest: false,
      disabledPlan: true,

      invest3: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " ",
        precision: 2,
        masked: true,
      },
      acess: "",
      invest: 0,
      invest2: 0,
      clicks: "",
      products: [],
      selected_channel: "",
      channels: [
        { id: 1, title: "MetaAds" },
        { id: 2, title: "GoogleAds" },
        { id: 3, title: "Twitter" },
        { id: 4, title: "Linkedin" },
        { id: 5, title: "TikTok" },
      ],
      selected_category: "",
      categories: [
        { id: 1, title: "Captura de Lead" },
        { id: 2, title: "Remarketing" },
        { id: 3, title: "Outros" },
      ],
      all_plans: [],
      selected_plan: "",
      funnels: [],
      selected_funnel: { id: null, title: "Selecione o funil de vendas" },
      loading: false,
    };
  },
  props: ["Traffic_selected"],
  watch: {
    // invest2(event) {
    //   var result = event.replace("R$ ", "").replace(",", ".").replace(".", "");
    //   this.invest = result.split(".").join("");
    // },
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return number.toLocaleString("pt-BR", formato);
      }
      return "0,00";
    },
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let nowtoday = new Date();
      nowtoday.getDay() + 1;

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, nowtoday],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    cancel() {
      (this.errorPlan = false),
        (this.errorChannel = false),
        (this.errorCategory = false);
    },

    getPlannel() {},
    changePlannel() {
      if (this.selected_plan.id === null) {
        this.selected_plan = { id: null, title: "Selecione o plano" };
        return;
      }
      var investimento = this.selected_plan.metas.find(
        (item) => item.meta_key == "investimento"
      ).meta_value;
      investimento = investimento
        .replace("R$ ", "")
        .replace(",", ".")
        .replace(".", "");
      this.invest = investimento;
    },
    onUpdate() {
      this.simulator_data = {
        clicks: this.clicks,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        campaigns_ids: JSON.stringify(this.selected_plan.campaigns.map(x => x.id)),      
      };
      var data = {
        id: this.Traffic_selected.id,
        plan_id: this.selected_plan.id,
        title: this.selected_plan.title,
        acess: this.acess,
        investing: this.invest2,
        origin: this.selected_channel.title,
        category:
          this.selected_category.title == "Outros"
            ? "others"
            : this.selected_category.title == "Remarketing"
            ? "remarketing"
            : this.selected_category.title == "Captura de Lead"
            ? "capture"
            : this.selected_category.title == "Selecione a categoria"
            ? ""
            : "",
        metas: this.simulator_data,
      };

      if (this.selected_plan == "" || !this.selected_plan) {
        this.errorPlan = true;
        this.loading = false;
      }

      if (this.selected_channel == "" || !this.selected_channel) {
        this.errorChannel = true;
        this.loading = false;
      }

      if (this.selected_category == "" || !this.errorCategory) {
        this.errorCategory = true;
        this.loading = false;
      }

      this.loading = true;
      serviceTraffic
        .update(data)
        .then((resp) => {
          this.$bvToast.toast("Tráfego atualizado com sucesso", {
            title: "Tráfego",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.selected_category = "";
          this.selected_channel = "";
          this.selected_plan = "";
          this.invest2 = "";
          this.clicks = "";

          //validaçoes
          (this.errorName = false),
            (this.errorPlan = false),
            (this.errorChannel = false),
            (this.errorCategory = false),
            //
            this.$bvModal.hide("Modal-EditTraffic");
          this.$emit("create-traffic");
        })
        .catch((error) => {
          this.$bvToast.toast("Erro ao atualizar tráfego", {
            title: "Tráfego",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInfos() {
      servicePlan
        .read(`/${this.Traffic_selected.plan_id}`)
        .then((resp) => {
          this.selected_plan = resp;

          this.invest2 = this.Traffic_selected.investing;
        })
        .finally(() => {
          this.loading = false;
        });

      servicePlan
        .search()
        .then((resp) => {
          this.all_plans = resp.data;
        })
        .finally(() => {
          this.loading = false;
        });
      serviceTraffic
        .createId(`/metas/${this.Traffic_selected.id}`)
        .then((resp) => {
          if (resp.find((x) => x.meta_key == "clicks")) {
            this.clicks = resp.find((x) => x.meta_key == "clicks").meta_value;
          } else {
            this.clicks = "";
          }
          if (resp.find((x) => x.meta_key == "startDate")) {
            this.dateRange.startDate = resp.find(
              (x) => x.meta_key == "startDate"
            ).meta_value;

            this.dateRange.endDate = resp.find(
              (x) => x.meta_key == "endDate"
            ).meta_value;
          } else {
            this.dateRange = "";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTraffic() {
      switch (this.Traffic_selected.category) {
        case "capture":
          this.selected_category = "Captura de Lead";
          break;
        case "remarketing":
          this.selected_category = "Remarketing";
          break;
        case "others":
          this.selected_category = "Outros";
          break;

        default:
          break;
      }
      this.selected_category = { title: this.selected_category };
      this.selected_channel = { title: this.Traffic_selected.origin };

      this.getInfos();
    },
  },
};
</script>

<style scoped lang="scss">
input,
#input {
  width: 100%;
}
.multiselect__input,
.multiselect__single {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #000;
}

.container-inputs {
  display: grid;
  gap: 20px;
}
.desc {
  color: #81858e;
  font-size: 14px;
  margin-top: -40px !important;
}
.loading {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.msgError {
  font-size: 12px;
  font-weight: 500;
  margin-left: 3px;

  color: #dc3545 !important;
}
</style>
